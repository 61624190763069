<div class="container-fluid mt-1">
    <div class="row">
        <div class="col-sm-3">
            <ul class="list-group">
                <li routerLink="/" routerLinkActive="active" class="list-group-item bg-warning">DashBoard</li>
                <li routerLink="landingpagejava" routerLinkActive="active" class="list-group-item">LandingPage Java</li>
                <li routerLink="landingpageasp" routerLinkActive="active" class="list-group-item">LandingPage ASP.Net</li>
                <li routerLink="landingpagephp" routerLinkActive="active" class="list-group-item">LandingPage PHP</li>
                <li routerLink="landingpagemobi" routerLinkActive="active" class="list-group-item">LandingPage Mobi</li>
            </ul>
        </div>
        <div class="col-sm-9">

            <router-outlet></router-outlet>
        </div>
    </div>