import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landingpage-manage',
  templateUrl: './landingpage-manage.component.html',
  styleUrls: ['./landingpage-manage.component.less']
})
export class LandingpageManageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
