<!-- nav -->

<nav class="navbar navbar-expand navbar-dark bg-dark" *ngIf="user">
    <div class="container">
        <a class="navbar-brand" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">VTI Dashboard</a>
        <!-- <a class="nav-item nav-link" routerLink="/users" routerLinkActive="active">Users</a> -->
        <a class="btn btn-primary" (click)="logout()">Logout</a>
    </div>
</nav>

<!-- main app container -->
<div class="app-container" [ngClass]="{ 'bg-light': user }">
    <alert></alert>
    <router-outlet></router-outlet>
</div>

<!-- credits -->
<div class="text-center mt-4">
    <p>
        <a>VTI LandingPage Management</a>
    </p>

</div>